<template>
  <div class="user-tab-config">
    <v-card class="mb-7">
      <v-card-title>Reseller</v-card-title>

      <v-card-text>
        <v-row>
          <v-col id="assign-reseller-checkbox" cols="12" md="6">
            <v-checkbox
              v-model="isAssignReseller"
              :label="`Assign Reseller`"
              @change="changeResellerAssign($event)"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- form -->
        <v-form  ref="form" class="multi-col-validation" @submit.prevent="onSubmitReseller">
          <v-row class="pb-4">
            <v-col v-if="isAssignReseller" cols="12" md="6">
              <v-autocomplete
              class="reseller"
              v-model="resellerModel"
              :items="resellers"
              item-value="id"
              item-text="accountName"
              :rules="[validators.required]"
              dense
              filled
              outlined
              clearable
              hide-details
              label="Reseller name"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="mb-4 save-reseller">
              <v-btn type="submit" color="primary"> Save </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row class="mt-0">
          <v-col id="assign-manager-checkbox" class="pt-0" cols="12" md="6">
            <v-checkbox
              class="mt-0"
              v-model="isAssignManager"
              :label="`Assign Manager`"
              @change="changeManagerAssign($event)"
            ></v-checkbox>
          </v-col>
        </v-row>
        <!-- form -->
        <v-form ref="managerForm" class="multi-col-validation" @submit.prevent="onSubmitManager">
          <v-row>
            <v-col v-if="isAssignManager" cols="12" md="6">
              <v-autocomplete
                class="manager"
                v-model="managerModel"
                :items="managers"
                item-value="id"
                item-text="fullName"
                :rules="[validators.required]"
                dense
                filled
                outlined
                clearable
                hide-details
                label="Manager name"
              ></v-autocomplete>
            </v-col>
            <v-col class="save-manager" cols="12">
              <v-btn type="submit" color="primary"> Save </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import RolesEnum from '@/enums/roles.enum';
import * as validators from '@core/utils/validation';

export default {
  setup() {
    const form = ref(null);
    const managerForm = ref(null);

    const user = store.getters['user/getSelectedUser'];

    const resellerModel = ref(user && user.reseller ? user.reseller.id : null);
    const resellers = ref([]);
    const isAssignReseller = ref(!!(user && user.reseller));

    const managerModel = ref(user && user.manager ? user.manager.id : null);
    const managers = ref([]);
    const isAssignManager = ref(!!(user && user.manager));

    const changeResellerAssign = (event) => {
      if (!event) {
        resellerModel.value = null;
      } else {
        if (user && user.reseller) {
          resellerModel.value = user.reseller.id;
        }
      }
    };

    const changeManagerAssign = (event) => {
      if (!event) {
        managerModel.value = null;
      } else {
        if (user && user.manager) {
          managerModel.value = user.manager.id;
        }
      }
    };

    const fetchResellers = () => {
      store
        .dispatch('user/fetchAll', {
          page: 1,
          itemsPerPage: '-1',
          role: RolesEnum.RESELLER,
        })
        .then(response => {
          resellers.value = response.data.data;
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Resellers',
            text: 'Cannot fetch resellers',
          });
        });
    };

    const fetchManagers = () => {
      store
        .dispatch('user/fetchAll', {
          page: 1,
          itemsPerPage: '-1',
          role: RolesEnum.ADMIN,
        })
        .then(response => {
          managers.value = response.data.data;
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Managers',
            text: 'Cannot fetch managers',
          });
        });
    };

    fetchResellers();
    fetchManagers();

    const onSubmitReseller = () => {
      const merchantId = router.currentRoute.params.id;
      store.dispatch('user/assignReseller', {
        id: merchantId,
        resellerId: resellerModel.value,
      }).then(() => {
        Vue.notify({
          type: 'success',
          title: 'Users',
          text: resellerModel.value ? 'Merchant reseller assigned' : 'Merchant reseller unassigned',
        });
      }).catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Users',
          text: 'Cannot assign merchant reseller',
        });
      });
    };

    const onSubmitManager = () => {
      const merchantId = router.currentRoute.params.id;
      store.dispatch('user/assignManager', {
        id: merchantId,
        managerId: managerModel.value,
      }).then(() => {
        Vue.notify({
          type: 'success',
          title: 'Users',
          text: managerModel.value ? 'Manager assigned' : 'Manager unassigned',
        });
      }).catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Users',
          text: 'Cannot assign manager',
        });
      });
    };

    return {
      changeResellerAssign,
      changeManagerAssign,
      validators,
      resellers,
      isAssignReseller,
      resellerModel,
      managers,
      isAssignManager,
      managerModel,
      form,
      managerForm,
      onSubmitReseller,
      onSubmitManager,
    };
  },
};
</script>
