<template>
  <v-dialog
    persistent
    v-model="isBioDialogOpen"
    max-width="650px"
  >
    <v-card class="edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Information
      </v-card-title>

      <v-card-text class="mt-5">
        <v-form
          class="multi-col-validation"
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userData.firstName"
                :rules="[validators.required]"
                outlined
                dense
                id="first_name"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="userData.lastName"
                :rules="[validators.required]"
                outlined
                dense
                id="last_name"
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <vue-phone-number-input id="phone" v-model="phoneNumber.phone" :no-use-browser-locale="true" v-bind="phoneNumber.props" @update="checkPhone($event)"/>
            </v-col>
            <v-col
              cols="12"
              sm="12"
            >
              <v-text-field
                v-model="userData.email"
                @change="errorMessages.email = null"
                :rules="[validators.required, validators.emailValidator]"
                :error-messages="errorMessages.email"
                outlined
                dense
                id="email"
                label="Email"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              v-if="userData.role === roles.ADMIN"
            >
              <v-text-field
                v-model="userData.salesBonus"
                @change="errorMessages.salesBonus = null"
                :rules="[validators.percentageValidator]"
                outlined
                dense
                id="sales-bonus"
                label="Sales Bonus, %"
              ></v-text-field>
            </v-col>

            <template v-if="userData.role === roles.MERCHANT || userData.role === roles.RESELLER">
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.billingEmail"
                  :rules="[validators.required, validators.emailValidator]"
                  outlined
                  dense
                  id="billing_email"
                  label="Billing Email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.accountName"
                  @change="errorMessages.accountName = null"
                  :rules="[validators.required]"
                  :error-messages="errorMessages.accountName"
                  outlined
                  dense
                  id="account_name"
                  label="Account Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="userData.company.billingCurrency"
                  :rules="[validators.required]"
                  :items="currencies"
                  outlined
                  dense
                  id="billing_currency"
                  label="Billing Currency"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.taxId"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  outlined
                  dense
                  id="tax_id"
                  label="Tax ID"
                ></v-text-field>
              </v-col>
              <v-col id='add-vat-to-billing-checkbox' cols="12" sm="12">
                <v-checkbox
                  v-model="userData.company.isVat"
                  label="Add vat to billing?"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.aim"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  outlined
                  dense
                  id="aim"
                  label="AIM"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.name"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  outlined
                  dense
                  id="company_name"
                  label="Company Name"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="userData.company.country"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  :items="countries"
                  outlined
                  dense
                  id="company_country"
                  label="Country"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.street"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  outlined
                  dense
                  id="company_street"
                  label="Company Street"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.city"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  outlined
                  dense
                  id="company_city"
                  label="Company City"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="userData.company.zip"
                  :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
                  outlined
                  dense
                  id="company_zip"
                  label="Company Zip"
                ></v-text-field>
              </v-col>
            </template>
            <template v-if="userData.role === roles.RESELLER">
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userData.bank.name"
                  outlined
                  dense
                  id="reseller-bank_name"
                  label="Bank name"
                  placeholder="Bank name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userData.bank.address"
                  outlined
                  dense
                  id="reseller-bank_address"
                  label="Bank address"
                  placeholder="Bank address"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userData.bank.swift"
                  outlined
                  dense
                  id="reseller-bank_swift"
                  label="SWIFT"
                  placeholder="SWIFT"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userData.bank.iban"
                  outlined
                  dense
                  id="reseller-bank_iban"
                  label="IBAN/Account number"
                  placeholder="IBAN/Account number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userData.bank.routingNumber"
                  outlined
                  dense
                  id="reseller-bank_routing_number"
                  label="Routing Number"
                  placeholder="Routing Number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="userData.bank.cryptoWallet"
                  outlined
                  dense
                  id="reseller-bank_crypto_wallet"
                  label="Crypto Wallet"
                  placeholder="Crypto Wallet"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </template>
            <v-col
              cols="12"
              class="d-flex justify-center mt-3"
            >
              <v-btn
                color="primary"
                class="me-3"
                type="submit"
                @click.prevent="onSubmit"
              >
                submit
              </v-btn>

              <v-btn
                outlined
                color="secondary"
                @click.prevent="close"
              >
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import countries from '@/enums/countries';
import Currency from '@/enums/currency.enum';
import { cleanSpace, emailValidator, required, percentageValidator } from '@core/utils/validation';
import Roles from '@/enums/roles.enum';
import { eventBus } from '@/main';
import handleError from '@/plugins/handle-error';
import store from '@/store';
import { phoneNumberField } from '@core/utils';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const roles = Roles;
    const userRole = Roles.USER;
    const userData = ref(JSON.parse(JSON.stringify(props.user)));
    const isBioDialogOpen = ref(false);
    const valid = ref(false);
    const form = ref(null);
    const phoneNumber = ref(phoneNumberField(userData.value.phone));
    const errorMessages = ref({ email: null, accountName: null });

    const checkPhone = ($event) => {
      if (!phoneNumber.value.phone) {
        return;
      }

      phoneNumber.value.results = $event;
      phoneNumber.value.props.error = !phoneNumber.value.results.isValid;
    };

    const onSubmit = () => {
      if (!form.value.validate()) return;

      if (phoneNumber.value.phone && !phoneNumber.value.results.isValid) {
        return;
      }

      if (userData.value.salesBonus !== null) {
        userData.value.salesBonus = userData.value.salesBonus.toString();
      }

      userData.value.phone = phoneNumber.value.results.formattedNumber;

      store.dispatch('user/update', cleanSpace(userData.value))
        .then(() => {
          isBioDialogOpen.value = false;
          eventBus.$emit('user.refetch');
        })
        .catch(error => (errorMessages.value = handleError(error)));
    };

    const close = () => {
      isBioDialogOpen.value = false;
      userData.value = JSON.parse(JSON.stringify(props.user));
    };

    onMounted(() => {
      eventBus.$on('user-edit.modal.show', (data) => {
        if (data && data.role) {
          userRole.value = data.role;
        }
        isBioDialogOpen.value = true;
      });
    });

    return {
      form,
      roles,
      isBioDialogOpen,
      countries,
      userRole,
      currencies: Object.values(Currency),
      errorMessages,
      userData,
      phoneNumber,
      checkPhone,
      onSubmit,
      close,
      valid,
      validators: { required, emailValidator, percentageValidator },
    };
  },
};
</script>
