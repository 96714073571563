var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-10"},[_c('v-card-title',{staticClass:"justify-center flex-column"},[_c('v-avatar',{staticClass:"mb-4",class:_vm.user.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.user.avatar ? '' : 'primary',"size":"120","rounded":""}},[_c('span',{staticClass:"font-weight-semibold text-5xl"},[_vm._v(_vm._s(_vm.avatarText(_vm.user.firstName + ' ' + _vm.user.lastName)))])]),_c('span',{staticClass:"full-name mb-2"},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" "),(_vm.user.company && _vm.user.company.accountName)?_c('span',{staticClass:"account-name"},[_vm._v("("+_vm._s(_vm.user.company.accountName)+")")]):_vm._e()]),_c('v-chip',{class:("v-chip-light-bg text-sm font-weight-semibold " + (_vm.resolveUserRoleVariant(
        _vm.user.role
      )) + "--text text-capitalize"),attrs:{"label":"","small":"","color":_vm.resolveUserRoleVariant(_vm.user.role)}},[_vm._v(" "+_vm._s(_vm.user.role)+" ")])],1),_c('v-card-text',[_c('h2',{staticClass:"text-xl font-weight-semibold mb-2"},[_vm._v("Details")]),_c('v-divider'),_c('v-list',[_c('v-list-item',{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium text-no-wrap me-2"},[_vm._v("Email:")]),_c('span',{staticClass:"email text--secondary"},[_vm._v(_vm._s(_vm.user.email))])]),_c('v-list-item',{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium text-no-wrap me-2"},[_vm._v("Phone:")]),_c('span',{staticClass:"phone text--secondary"},[(_vm.user.isPhoneVerified)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircleOutline)+" ")])]}}],null,false,653887271)},[_c('span',[_vm._v("Phone verified")])]):_vm._e(),_vm._v(" "+_vm._s(_vm.user.phone))],1)]),_c('v-list-item',{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Status:")]),_c('span',{staticClass:"status text--secondary"},[_c('v-chip',{class:("v-chip-light-bg " + (_vm.resolveUserStatusVariant(
              _vm.user.status
            )) + "--text font-weight-medium text-capitalize"),attrs:{"small":"","label":"","color":_vm.resolveUserStatusVariant(_vm.user.status)}},[_vm._v(" "+_vm._s(_vm.user.status)+" ")])],1)]),_c('v-list-item',{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Role:")]),_c('span',{staticClass:"role text--secondary text-capitalize"},[_vm._v(_vm._s(_vm.user.role))])]),(_vm.user.role === _vm.role.ADMIN)?_c('v-list-item',{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Sales Bonus:")]),_c('span',{staticClass:"sales-bonus text--secondary text-capitalize"},[_vm._v(_vm._s(_vm.user.salesBonus || 0)+"%")])]):_vm._e()],1),(_vm.user.company)?_c('user-company',{attrs:{"company":_vm.user.company}}):_vm._e(),(_vm.user.bank)?_c('user-bank',{attrs:{"bank":_vm.user.bank}}):_vm._e()],1),(_vm.isAdmin)?_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"edit-button me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.edit()}}},[_vm._v(" Edit ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }