<template>
  <v-row class="bio-panel">
    <v-col v-if="user" cols="12">
      <user-info :user="user"></user-info>
      <user-edit :user="user"></user-edit>
    </v-col>
  </v-row>
</template>

<script>
import UserInfo from '@/views/shared/users/_partials/UserInfo.vue';
import UserEdit from '@/views/shared/users/_partials/UserEdit.vue';
import router from '@/router';
import store from '@/store';
import { onMounted, ref } from '@vue/composition-api';
import { eventBus } from '@/main';
import { getProfile } from '@/services/jwt.service';

export default {
  components: {
    UserEdit,
    UserInfo,
  },
  setup() {
    const user = ref(null);
    const fetchUser = () => {
      store
        .dispatch('user/fetchById', router.currentRoute.params.id ? router.currentRoute.params.id : getProfile().id)
        .then(response => {
          user.value = response.data;
        })
        .catch(error => {
          if (error.response.status === 404) {
            user.value = null;
          }
        });
    };

    fetchUser();

    onMounted(() => {
      eventBus.$on('user.refetch', () => {
        fetchUser();
      });
    });

    return {
      user,
    };
  },
};
</script>
<style>
.merchant-email {
  overflow: auto;
}
</style>
