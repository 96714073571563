import store from '@/store';
import { computed, onMounted, ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import Roles from '@/enums/roles.enum';
import { eventBus } from '@/main';

export const resolveUserRoleVariant = role => {
  if (role === Roles.MERCHANT) return 'primary';
  if (role === Roles.ADMIN) return 'error';

  return 'primary';
};

export const resolveUserStatusVariant = status => {
  if (status === 'active') {
    return 'success';
  }
  return 'error';
};

export const statusOptions = [
  { title: 'Active', value: 'active' },
  { title: 'Inactive', value: 'inactive' },
];

export const isAdmin = computed(() => store.state.auth.profile && store.state.auth.profile.role === Roles.ADMIN);

export default function useUsers(role, companyId) {
  const users = ref([]);

  const tableColumns = [
    { text: 'Name', value: 'fullName', sortable: false },
    { text: 'User Email', value: 'email', sortable: false },
    { text: 'Phone', value: 'phone', sortable: false },
    { text: 'Company Name', value: 'companyName', sortable: false },
    { text: 'Status', value: 'status', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  switch (role) {
    case Roles.ADMIN:
    case Roles.USER:
      tableColumns.splice(3, 1);
      break;
    case Roles.MERCHANT:
      tableColumns.splice(2, 1);
      tableColumns.splice(3, 0, {
        text: 'Manager',
        value: 'manager',
        sortable: false,
      });
      tableColumns.splice(0, 0, { text: 'ID', value: 'id', sortable: false });

      tableColumns.splice(5, 0, {
        text: 'API',
        value: 'apiSetting',
        sortable: false,
      });
      break;
  }

  const searchQuery = ref('');
  const statusFilter = ref('');
  const countUsers = ref(0);
  const loading = ref(false);
  const options = ref({
    sortBy: ['createdAt'],
    sortDesc: [true],
  });
  const newStatus = ref(null);
  const admins = ref([
    { id: 'not-assigned', firstName: 'No', lastName: 'Assignee' },
  ]);
  const managersFilter = ref(null);

  // fetch data
  const fetchUsers = () => {
    store
      .dispatch('user/fetchAll', {
        q: searchQuery.value,
        status: statusFilter.value,
        sortBy: options.value.sortBy[0],
        sortDesc: options.value.sortDesc[0],
        page: options.value.page,
        companyId: companyId ? companyId : null,
        managerId: managersFilter.value,
        role: role,
        itemsPerPage: options.value.itemsPerPage,
      })
      .then(response => {
        users.value = response.data.data;
        countUsers.value = response.data.totalCount;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Users',
          text: 'Cannot fetch users',
        });
      });
  };

  const fetchAdmins = () => {
    store
      .dispatch('user/fetchAdmins')
      .then(response => {
        admins.value = admins.value.concat(response.data);
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Managers',
          text: 'Cannot fetch managers',
        });
      });
  };

  const reset2fa = (user) => {
    Vue.$confirm({
      message: `Are you sure you want to reset 2fa for ${user.email}?`,
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: confirm => {
        if (confirm) {
          store.dispatch('user/reset2fa', { id: user.id })
            .then(() => {
              Vue.notify({
                type: 'success',
                title: 'Users',
                text: 'Successfully reset 2fa',
              });
            })
            .catch(() => {
              Vue.notify({
                type: 'error',
                title: 'Admins',
                text: `Cannot reset 2fa for user with id ${user.id}`,
              });
            });
        }
      },
    });
  };

  const resendPasswordChangeRequest = (id) => {
    try {
      store.dispatch('user/resendPasswordChangeRequest', id);
      Vue.notify({
        type: 'success',
        title: 'Merchants',
        text: 'Password reset email sent successfully',
      });
    } catch (e) {
      Vue.notify({
        type: 'error',
        title: 'Merchants',
        text: 'Cannot send email for reset password',
      });
    }
  };

  const updateStatus = (id, status) => {
    return store.dispatch('user/patchStatus', { id: id, status: { status: status } }).then(() => {
      (users.value.find((item) => item.id === id)).status = status;
      newStatus.value = null;
      return status;
    }).catch(() => {
      Vue.notify({
        type: 'error',
        title: 'Users',
        text: `Cannot update user status with id ${id}`,
      });
    });
  };

  const itemClass = () => {
    return 'user-item';
  };

  const openUserAddModal = () => {
    eventBus.$emit('user-add.modal.show', ('user-add.modal.show', {
      role: role,
      companyId: companyId,
    }));
  };

  const deleteUser = user => {
    Vue.$confirm({
      message: `Are you sure you want to delete ${user.fullName}?`,
      button: {
        no: 'No',
        yes: 'Yes',
      },
      callback: confirm => {
        if (confirm) {
          const userId = user.id;
          store
            .dispatch('user/delete', user.id)
            .then(() => {
              const userIndex = users.value.findIndex((user) => user.id === userId);
              if (userIndex > -1) {
                users.value.splice(userIndex, 1);
              }
            })
            .catch(() => {
              Vue.notify({
                type: 'error',
                title: 'User',
                text: 'Cannot delete user',
              });
            });
        }
      },
    });
  };

  const roleEnum = Roles;

  watch([searchQuery, statusFilter, managersFilter], () => {
    loading.value = true;
    fetchUsers();
  });

  if (isAdmin.value === true) {
    fetchAdmins();
  }

  watch([options], () => {
    loading.value = true;
    fetchUsers();
  });

  onMounted(() => {
    eventBus.$on('user.refetch', () => {
      fetchUsers();
    });
  });

  return {
    admins,
    users,
    roleEnum,
    tableColumns,
    searchQuery,
    statusFilter,
    managersFilter,
    countUsers,
    loading,
    options,
    newStatus,
    openUserAddModal,
    fetchUsers,
    itemClass,
    reset2fa,
    updateStatus,
    resendPasswordChangeRequest,
    deleteUser,
  };
}
