<template>
  <div>
    <h2 class="text-xl font-weight-semibold mb-2">Company</h2>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-if="company.id" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">ID:</span>
        <span class="billing-email text--secondary text-break">{{ company.id }}</span>
      </v-list-item>
      <v-list-item v-if="company.billingEmail" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Billing Email:</span>
        <span class="billing-email text--secondary text-break">{{ company.billingEmail }}</span>
      </v-list-item>
      <v-list-item v-if="company.billingCurrency" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Billing Currency:</span>
        <span class="billing-currency text--secondary text-break">{{ company.billingCurrency }}</span>
      </v-list-item>
      <v-list-item v-if="company.taxId" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Tax ID:</span>
        <span class="tax-id text--secondary">{{ company.taxId }}</span>
      </v-list-item>
      <v-list-item v-if="company.aim" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">AIM:</span>
        <span class="aim text--secondary">{{ company.aim }}</span>
      </v-list-item>
      <v-list-item dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">VAT:</span>
        <span class="vat text--secondary">{{ company.isVat ? 'Yes' : 'No' }}</span>
      </v-list-item>
      <v-list-item v-if="company.name" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Name:</span>
        <span class="company-name text--secondary">{{ company.name }}</span>
      </v-list-item>
      <v-list-item v-if="company.street" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Street:</span>
        <span class="company-street text--secondary">{{ company.street }}</span>
      </v-list-item>
      <v-list-item v-if="company.country" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Country:</span>
        <span class="company-country text--secondary">{{ company.country }}</span>
      </v-list-item>
      <v-list-item v-if="company.city" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">City:</span>
        <span class="company-city text--secondary">{{ company.city }}</span>
      </v-list-item>
      <v-list-item v-if="company.zip" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Zip:</span>
        <span class="company-zip text--secondary">{{ company.zip }}</span>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
</style>
