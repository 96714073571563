<template>
  <div class="user-tab-config">
    <v-card class="mb-7">
      <v-card-title> Change Comment </v-card-title>

      <v-card-text>
        <!-- form -->
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col>
              <v-textarea
                v-model="comment"
                outlined
                dense
                id="comment"
                :rules="[validators.required]"
                label="Comment"
                type="text"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-btn type="submit" color="primary"> Change </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import { required } from '@core/utils/validation';

export default {
  setup() {
    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };
    const user = store.getters['user/getSelectedUser'];
    const comment = ref(user.comment);

    const onSubmit = () => {
      if (valid.value) {
        const userId = router.currentRoute.params.id;
        store.dispatch('user/changeComment', {
          id: userId,
          comment: comment.value,
        });
      } else {
        validate();
      }
    };

    return {
      form,
      onSubmit,
      valid,
      validate,
      validators: { required },
      comment,
    };
  },
};
</script>
