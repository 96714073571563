<template>
  <v-navigation-drawer v-if="isAddNewUserSidebarActive" class="add-new-bar"
    :value="isAddNewUserSidebarActive"
    stateless
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary text-capitalize">Add New {{userData.role}}</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="isAddNewUserSidebarActive = false">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="userData.firstName"
            outlined
            dense
            id="first_name"
            :rules="[validators.required]"
            label="First Name"
            placeholder="John"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.lastName"
            outlined
            dense
            id="last_name"
            :rules="[validators.required]"
            label="Last Name"
            placeholder="Doe"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required, validators.emailValidator]"
            :error-messages="errorMessages.email"
            outlined
            dense
            id="email"
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
            @change="errorMessages.email = null"
          ></v-text-field>

          <div class="mb-5">
            <vue-phone-number-input id="-phone" v-model="phoneNumber.phone" :no-use-browser-locale="true" v-bind="phoneNumber.props" @update="checkPhone($event)"/>
          </div>

          <template v-if="userData.company">
            <v-text-field
              v-model="userData.company.accountName"
              outlined
              dense
              id="account_name"
              :rules="[validators.required]"
              label="Account Name"
              placeholder="Account"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.company.aim"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              outlined
              dense
              id="aim"
              label="AIM"
            ></v-text-field>

            <v-text-field
              v-model="userData.company.taxId"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              outlined
              dense
              id="tax_id"
              type="text"
              label="Tax Id"
              placeholder="Tax Id"
              hide-details="auto"
            ></v-text-field>

            <v-col id='add-vat-to-billing-checkbox' cols="12" sm="12">
              <v-checkbox
                v-model="userData.company.isVat"
                label="Add vat to billing?"
              ></v-checkbox>
            </v-col>

            <v-text-field
              v-model="userData.company.name"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              outlined
              dense
              id="company_name"
              label="Company Name"
              placeholder="Company"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.company.street"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              outlined
              dense
              id="company_street"
              label="Company Street"
              placeholder="Street"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-select
              v-model="userData.company.country"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              label="Country"
              :items="countries"
              outlined
              dense
              id="company_country"
              hide-details="auto"
              class="mb-6"
            >
            </v-select>

            <v-text-field
              v-model="userData.company.city"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              outlined
              dense
              id="company_city"
              label="Company City"
              placeholder="City"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.company.zip"
              :rules="userData.role === roles.MERCHANT ? [validators.required] : []"
              outlined
              dense
              id="company_zip"
              label="Company Zip"
              placeholder="Zip"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.company.billingEmail"
              :rules="[validators.required, validators.emailValidator]"
              outlined
              dense
              id="billing_email"
              type="email"
              label="Billing Email"
              placeholder="Billing Email"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-select
              v-model="userData.company.billingCurrency"
              :rules="[validators.required]"
              label="Billing Currency"
              :items="currencies"
              id="currency"
              outlined
              dense
              hide-details="auto"
              class="mb-6"
            >
            </v-select>
          </template>

          <template v-if="userData.bank">
            <v-text-field
              v-model="userData.bank.name"
              outlined
              dense
              id="bank_name"
              label="Bank name"
              placeholder="Bank name"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.bank.address"
              outlined
              dense
              id="bank_address"
              label="Bank address"
              placeholder="Bank address"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.bank.swift"
              outlined
              dense
              id="bank_swift"
              label="Swift"
              placeholder="Swift"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.bank.iban"
              outlined
              dense
              id="bank_iban"
              label="Iban"
              placeholder="Iban"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.bank.routingNumber"
              outlined
              dense
              id="bank_routing_number"
              label="Routing Number"
              placeholder="Routing Number"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>

            <v-text-field
              v-model="userData.bank.cryptoWallet"
              outlined
              dense
              id="bank_crypto_wallet"
              label="Crypto Wallet"
              placeholder="Crypto Wallet"
              hide-details="auto"
              class="mb-6"
            ></v-text-field>
          </template>

          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetUserData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js';
import countries from '@/enums/countries';
import Currency from '@/enums/currency.enum';
import store from '@/store';
import { onMounted, ref } from '@vue/composition-api';
import { required, emailValidator, cleanSpace } from '@core/utils/validation';
import handleError from '@/plugins/handle-error';
import { phoneNumberField } from '@core/utils';
import { eventBus } from '@/main';
import RolesEnum from '@/enums/roles.enum';

export default {
  setup() {
    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      role: RolesEnum.ADMIN
    };

    const phoneNumber = ref(phoneNumberField(''));
    const errorMessages = ref({ email: null, accountName: null });

    const valid = ref(false);
    const roles = RolesEnum;
    const isAddNewUserSidebarActive = ref(false);
    const userData = ref(blankUserData);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const checkPhone = ($event) => {
      phoneNumber.value.results = $event;
      phoneNumber.value.props.error = !phoneNumber.value.results.isValid;
    };

    const resetUserData = () => {
      phoneNumber.value = phoneNumberField('');
      form.value.reset();
      isAddNewUserSidebarActive.value = false;
    };

    const onSubmit = () => {
      const isFormValid = form.value.validate();
      if (!isFormValid) return;

      if (phoneNumber.value.phone && !phoneNumber.value.results.isValid) {
        return;
      }

      userData.value.phone = phoneNumber.value.results.formattedNumber;

      store
        .dispatch('user/store', cleanSpace(userData.value))
        .then(() => {
          eventBus.$emit('user.refetch');
          resetUserData();
        })
        .catch(error => (errorMessages.value = handleError(error)));
    };

    onMounted(() => {
      eventBus.$on('user-add.modal.show', (data) => {
        userData.value.role = data.role;
        userData.value.companyId = data.companyId;
        if (data.role === RolesEnum.MERCHANT || data.role === RolesEnum.RESELLER) {
          userData.value = {
            ...userData.value,
            company: {
              name: '',
              street: '',
              country: '',
              accountName: '',
              city: '',
              zip: '',
              taxId: '',
              billingEmail: '',
              billingCurrency: Currency.EUR,
            }
          };
        }

        if (data.role === RolesEnum.RESELLER) {
          userData.value = {
            ...userData.value,
            bank: {
              name: '',
              address: '',
              swift: '',
              iban: '',
              routingNumber: '',
              cryptoWallet: '',
            }
          };
        }

        isAddNewUserSidebarActive.value = true;
      });
    });

    return {
      resetUserData,
      form,
      onSubmit,
      countries,
      roles,
      currencies: Object.values(Currency),
      userData,
      isAddNewUserSidebarActive,
      errorMessages,
      phoneNumber,
      checkPhone,
      valid,
      validate,
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    };
  },
};
</script>
