<template>
  <div class="user-tab-config">
    <v-card>
      <v-card-title>Billing Settings</v-card-title>

      <v-card-text>
        <!-- form -->
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col id="detailed-breakdown-invoices-checkbox" cols="12" md="12" class='py-1'>
              <v-checkbox
                v-model="user.company.isBillingDetailInvoice"
                :label="`Detailed breakdown in invoices`"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col id="monthly-minimum-checkbox" cols="12" md="12" class='py-1'>
              <v-checkbox
                v-model="isEnableBillingMonthlyMinimum"
                :label="`Monthly minimum`"
                @change="changeBillingMinimumCheckbox($event)"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col v-if="isEnableBillingMonthlyMinimum" cols="12" md="12">
              <v-text-field
                v-model="user.company.billingMonthlyMinimum"
                outlined
                dense
                :rules="[validators.required, validators.between(user.company.billingMonthlyMinimum, 1, 10000000)]"
                id="monthly-minimum"
                label="Monthly minimum"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col id="custom-billing-credentials-select" cols="12" md="12" class='py-1'>
              <v-checkbox
                v-model="isEnableCustomBillingCredentials"
                label="Custom Billing Credentials"
                @change="changeCustomBillingCredentials($event)"
                hide-details
                dense
              ></v-checkbox>
            </v-col>
            <v-col v-if="isEnableCustomBillingCredentials" cols="12" md="12">
              <v-select
                v-if="billingCredentials"
                v-model="user.company.customBillingCredentials"
                :items="billingCredentials"
                :rules="[validators.required]"
                item-value="id"
                item-text="name"
                outlined
                dense
                id="custom-billing-credentials"
                label="Billing Credentials"
              ></v-select>
            </v-col>
            <v-col cols="12" class="save-billing-minimum">
              <v-btn type="submit" color="primary"> Save </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import Vue from 'vue';
import * as validators from '@core/utils/validation';
import { eventBus } from '@/main';
import { isObject } from '@core/utils';

export default {
  setup() {
    const form = ref(null);
    const valid = ref(false);
    const user = computed(() => store.getters['user/getSelectedUser']);

    const validate = () => {
      form.value.validate();
    };

    const isEnableBillingMonthlyMinimum = ref(!!(user.value.company && user.value.company.billingMonthlyMinimum));
    const isEnableCustomBillingCredentials = ref(!!(user.value.company && user.value.company.customBillingCredentials));

    watch(user, (updatedUser, oldUser) => {
      if (!updatedUser || !updatedUser.company) {
        return;
      }

      if (oldUser.company && updatedUser.company.billingCurrency !== oldUser.company.billingCurrency) {
        fetchBillingCredentials();
      }

      if (updatedUser.company.customBillingCredentials === null) {
        isEnableCustomBillingCredentials.value = false;
      }
    });

    const changeBillingMinimumCheckbox = (event) => {
      if (!event) {
        user.value.company.billingMonthlyMinimum = null;
      }
    };

    const changeCustomBillingCredentials = (event) => {
      if (!event) {
        user.value.company.customBillingCredentials = null;
      }
    };

    const onSubmit = () => {
      const isFormValid = form.value.validate();
      if (!isFormValid) return;

      if (user.value.company && isObject(user.value.company.customBillingCredentials)) {
        user.value.company.customBillingCredentials = user.value.company.customBillingCredentials.id;
      }

      store.dispatch('company/patchCompany', {
        id: user.value.company.id,
        company: user.value.company,
      }).then(() => {
        eventBus.$emit('user.refetch');

        Vue.notify({
          type: 'success',
          title: 'Billing Settings',
          text: 'Billing Settings has been successfully updated',
        });
      }).catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Billing Settings',
          text: 'Cannot update Billing Settings',
        });
      });
    };

    const billingCredentials = ref(null);

    const fetchBillingCredentials = () => {
      store.dispatch('billingCredentials/fetchBillingCredentials', {
        itemsPerPage: '-1',
        currency: user.value.company.billingCurrency,
      })
        .then(response => {
          billingCredentials.value = response.data.filter(credentials => !credentials.isDefault);
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Billing Credentials',
            text: 'Cannot fetch billing credentials',
          });
        });
    };

    fetchBillingCredentials();

    return {
      validate,
      valid,
      isEnableBillingMonthlyMinimum,
      isEnableCustomBillingCredentials,
      billingCredentials,
      changeBillingMinimumCheckbox,
      changeCustomBillingCredentials,
      form,
      onSubmit,
      validators,
      user,
    };
  },
};
</script>
