<template>
  <div class="user-tab-config">
    <v-card class="mb-7">
      <v-card-title> Change Merchant Services </v-card-title>

      <v-card-text>
        <!-- form -->
        <v-form ref="form" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedUserServices"
                :items="userServices"
                :menu-props="{ maxHeight: '400' }"
                item-value="value"
                item-text="title"
                label="Select merchant services"
                multiple
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-btn type="submit" color="primary"> Save </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '@/router';
import { UserServices } from '@/enums/user-services.enum';
import store from '@/store';
import Vue from 'vue';

export default {
  setup() {
    const form = ref(null);
    const user = store.getters['user/getSelectedUser'];
    const selectedUserServices = ref(user.services);
    const userServices = UserServices;

    const onSubmit = () => {
      const userId = router.currentRoute.params.id;
      store.dispatch('user/assignService', {
        id: userId,
        services: selectedUserServices.value,
      }).then(() => {
        Vue.notify({
          type: 'success',
          title: 'Users',
          text: 'User settings service saved',
        });
      }).catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Users',
          text: 'Cannot save user settings service',
        });
      });
    };

    return {
      form,
      onSubmit,
      userServices,
      selectedUserServices,
    };
  },
};
</script>
