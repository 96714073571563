<template>
  <div v-if="bank.name">
    <h2 class="text-xl font-weight-semibold mb-2">Bank</h2>
    <v-divider></v-divider>
    <v-list>
      <v-list-item v-if="bank.name" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Bank Name:</span>
        <span class="bank-name text--secondary text-break">{{ bank.name }}</span>
      </v-list-item>
      <v-list-item v-if="bank.address" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Bank Address:</span>
        <span class="bank-address text--secondary text-break">{{ bank.address }}</span>
      </v-list-item>
      <v-list-item v-if="bank.swift" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Swift:</span>
        <span class="bank-swift text--secondary">{{ bank.swift }}</span>
      </v-list-item>
      <v-list-item v-if="bank.iban" dense class="px-0 mb-n2">
        <span class="font-weight-medium me-2">Iban:</span>
        <span class="bank-iban text--secondary">{{ bank.iban }}</span>
      </v-list-item>
      <v-list-item v-if="bank.routingNumber" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Routing Number:</span>
        <span class="bank-routing_number text--secondary">{{ bank.routingNumber }}</span>
      </v-list-item>
      <v-list-item v-if="bank.cryptoWallet" dense class="px-0 mb-n2">
        <span class="font-weight-medium text-no-wrap me-2">Crypto Wallet:</span>
        <span class="bank-crypto_wallet text--secondary">{{ bank.cryptoWallet }}</span>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>

export default {
  props: {
    bank: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
</style>
