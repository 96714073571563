import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import Vue from 'vue';

export default function useWebsitesList(companyId) {
  const websitesListTable = ref([]);

  const tableColumns = [
    { text: 'Website Name', value: 'name', sortable: false },
    { text: 'MCC Code', value: 'mcc', sortable: false },
    { text: 'Status', value: 'status', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];
  const searchQuery = ref('');
  const loading = ref(false);

  // fetch data
  const fetchWebsites = (companyId) => {
    store
      .dispatch('website/fetchWebsitesByCompanyId', {
        companyId: companyId,
        query: searchQuery.value,
      })
      .then(response => {
        websitesListTable.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Websites',
          text: 'Cannot fetch websites',
        });
      });
  };

  fetchWebsites(companyId);

  const resolveWebsiteStatusVariant = status => {
    if (status === 'active') {
      return 'success';
    }
    return 'error';
  };

  watch([searchQuery], () => {
    loading.value = true;
    fetchWebsites(companyId);
  });

  return {
    websitesListTable,
    tableColumns,
    loading,
    searchQuery,
    fetchWebsites,
    resolveWebsiteStatusVariant,
  };
}
