<template>
  <v-navigation-drawer
    :value="isAddNewWebsiteSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-new-website-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Website</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-new-website-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="websiteData.name"
            outlined
            dense
            id="website-name"
            :rules="[validators.required]"
            label="Website Name"
            placeholder="Google"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="websiteData.mcc"
            outlined
            dense
            id="website-mcc"
            :rules="[validators.required]"
            label="MCC"
            type="number"
            placeholder="MCC"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetWebsiteData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js';
import store from '@/store';
import { ref } from '@vue/composition-api';
import { cleanSpace, required } from '@core/utils/validation';
import router from '@/router';
import Vue from 'vue';

export default {
  model: {
    prop: 'isAddNewWebsiteSidebarActive',
    event: 'update:is-add-new-website-sidebar-active',
  },
  props: {
    isAddNewWebsiteSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankWebsiteData = {
      name: '',
      mcc: '',
    };

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const resetForm = () => {
      form.value.reset();
    };

    const websiteData = ref(JSON.parse(JSON.stringify(blankWebsiteData)));
    const resetWebsiteData = () => {
      websiteData.value = JSON.parse(JSON.stringify(blankWebsiteData));
      resetForm();
      emit('update:is-add-new-website-sidebar-active', false);
    };

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('website/storeWebsite', { merchantId: router.currentRoute.params.id, website: cleanSpace(websiteData.value) })
          .then(() => {
            emit('refetch-data');
            emit('update:is-add-new-website-sidebar-active', false);
            resetWebsiteData();
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Website',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    return {
      resetWebsiteData,
      form,
      onSubmit,
      websiteData,
      valid,
      validate,
      validators: { required },
      icons: {
        mdiClose,
      },
    };
  },
};
</script>
