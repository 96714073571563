<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title> Change Password </v-card-title>

      <v-card-text>
        <v-alert v-if="isShowSuccessAlert" border="bottom" color="success" text dark> Password changed! </v-alert>
        <v-alert v-else color="warning" text>
          <p class="font-weight-semibold mb-1">Ensure that these requirements are met</p>
          <p class="text-sm mb-0">Minimum 8 characters long, uppercase &amp; number</p>
        </v-alert>

        <!-- form -->
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newPassword"
                outlined
                dense
                id="password"
                :rules="[validators.required, validators.passwordValidator]"
                label="New Password"
                :type="isPasswordVisible ? 'text' : 'password'"
                :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="confirmPassword"
                outlined
                dense
                id="confirm_password"
                label="Confirm New Password"
                :rules="[validators.required, validators.confirmedValidator(confirmPassword, newPassword)]"
                :type="isPasswordConfirmVisible ? 'text' : 'password'"
                :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
                @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary"> Change Password </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
import { ref } from '@vue/composition-api';
import store from '@/store';
import { confirmedValidator, passwordValidator, required } from '@core/utils/validation';
import router from '@/router';
import { getProfile } from '@/services/jwt.service';

export default {
  setup() {
    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const newPassword = ref('');
    const confirmPassword = ref('');
    const isShowSuccessAlert = ref(false);
    const isPasswordVisible = ref(false);
    const isPasswordConfirmVisible = ref(false);

    const onSubmit = () => {
      if (valid.value) {
        const userId = router.currentRoute.params.id ? router.currentRoute.params.id : getProfile().id;
        store.dispatch('user/setNewPassword', {
          id: userId,
          passwordData: {
            password: newPassword.value,
            confirmPassword: confirmPassword.value,
          },
        }).then(() => {
          isShowSuccessAlert.value = true;
          setTimeout(() => {
            isShowSuccessAlert.value = false;
          }, 3000);
        });
      } else {
        validate();
      }
    };

    return {
      form,
      onSubmit,
      valid,
      validate,
      validators: { required, passwordValidator, confirmedValidator },
      newPassword,
      confirmPassword,
      isShowSuccessAlert,
      isPasswordVisible,
      isPasswordConfirmVisible,
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
};
</script>
