<template>
  <div v-if="merchantData" id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <user-bio-panel :user="merchantData"></user-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-card-title v-if="role === userRole.ADMIN" class="pl-0">
          <v-icon class="return-button" @click="$router.back()">{{ icons.mdiKeyboardBackspace }}</v-icon>
          <span class="ml-3">
            Merchant <span class="font-weight-bold"> {{ merchantData.firstName }}&nbsp;{{ merchantData.lastName }}</span>
          </span>
        </v-card-title>
        <v-card-title v-else class="pl-0">
          <v-icon class="return-button" @click="$router.back()">{{ icons.mdiKeyboardBackspace }}</v-icon>
          <span class="ml-3">
            Merchant <span class="font-weight-bold"> {{ merchantData.firstName }}&nbsp;{{ merchantData.lastName }}</span>
          </span>
        </v-card-title>

        <merchant-website v-if="role === userRole.ADMIN && merchantData.role !== userRole.USER" :company-id="merchantData.company.id"></merchant-website>
        <merchant-manager-list v-if="role === userRole.ADMIN && merchantData.role !== userRole.USER" :company-id="merchantData.company.id"></merchant-manager-list>
        <v-tabs v-model="merchantTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.id" :id="tab.id">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-if="merchantData.role !== userRole.USER" id="merchant-tabs-content" v-model="merchantTab" class="mt-5 pa-1">
          <v-tab-item>
            <user-security></user-security>
          </v-tab-item>

          <v-tab-item v-if="role === userRole.ADMIN">
            <user-comment></user-comment>
          </v-tab-item>

          <v-tab-item v-if="role === userRole.ADMIN && merchantData.role === userRole.MERCHANT">
            <merchant-service></merchant-service>
          </v-tab-item>

          <v-tab-item v-if="role === userRole.ADMIN && merchantData.role === userRole.MERCHANT">
            <merchant-reseller></merchant-reseller>
          </v-tab-item>

          <v-tab-item v-if="role === userRole.ADMIN && merchantData.role === userRole.MERCHANT">
            <merchant-billing-settings></merchant-billing-settings>
          </v-tab-item>
        </v-tabs-items>

        <v-tabs-items v-if="merchantData.role === userRole.USER" id="merchant-tabs-content" v-model="merchantTab" class="mt-5 pa-1">
          <v-tab-item>
            <user-security></user-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import {
  mdiCommentOutline,
  mdiDebian,
  mdiLockOutline,
  mdiKeyboardBackspace,
  mdiHumanMale, mdiAccountCashOutline,
} from '@mdi/js';
import MerchantWebsite from '@/views/shared/users/merchant/merchant-website/MerchantWebsite.vue';
import MerchantService from '@/views/shared/users/merchant/_partials/MerchantService.vue';
import Roles from '@/enums/roles.enum';
import { getProfile } from '@/services/jwt.service';
import UserBioPanel from '@/views/shared/users/UserBioPanel.vue';
import UserSecurity from '@/views/shared/users/_partials/UserSecurity.vue';
import UserComment from '@/views/shared/users/_partials/UserComment.vue';
import MerchantReseller from '@/views/shared/users/merchant/_partials/MerchantReseller.vue';
import MerchantManagerList from '@/views/shared/users/merchant/_partials/MerchantManagerList.vue';
import MerchantBillingSettings
  from '@/views/shared/users/merchant/_partials/MerchantBillingSettings.vue';

export default {
  components: {
    MerchantBillingSettings,
    MerchantManagerList,
    MerchantReseller,
    UserComment,
    UserSecurity,
    UserBioPanel,
    MerchantWebsite,
    MerchantService,
  },
  setup() {
    const merchantData = ref(null);
    const merchantTab = ref(null);
    const userRole = Roles;
    const role = getProfile().role;
    let tabs = [];
    if (role === Roles.ADMIN) {
      tabs = [
        { icon: mdiLockOutline, title: 'Security', id: 'merchant-security-tab' },
        { icon: mdiCommentOutline, title: 'Comment', id: 'merchant-comment-tab' },
        { icon: mdiDebian, title: 'Service', id: 'merchant-service-tab' },
        { icon: mdiHumanMale, title: 'Assign', id: 'merchant-reseller-tab' },
        { icon: mdiAccountCashOutline, title: 'Billing Settings', id: 'merchant-billing-settings-tab' },
      ];
    } else {
      tabs = [
        { icon: mdiLockOutline, title: 'Security', id: 'merchant-security-tab' },
      ];
    }

    store
      .dispatch('user/fetchById', router.currentRoute.params.id)
      .then(response => {
        merchantData.value = response.data;

        if (merchantData.value.role === Roles.USER) {
          if (role !== Roles.MERCHANT) {
            tabs.pop();
            tabs.pop();
            tabs.pop();
            tabs.pop();
          }
          return;
        }

        if (role === Roles.ADMIN) {
          return;
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          merchantData.value = {};
        }
      });

    return {
      role,
      tabs,
      userRole,
      merchantTab,
      merchantData,
      icons: {
        mdiKeyboardBackspace,
      },
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
