<template>
  <v-card class="pt-10">
    <v-card-title class="justify-center flex-column">
      <v-avatar
        :color="user.avatar ? '' : 'primary'"
        :class="user.avatar ? '' : 'v-avatar-light-bg primary--text'"
        size="120"
        rounded
        class="mb-4"
      >
        <span class="font-weight-semibold text-5xl">{{
            avatarText(user.firstName + ' ' + user.lastName)
          }}</span>
      </v-avatar>

      <span class="full-name mb-2">
        {{ user.firstName }}&nbsp;{{ user.lastName }}
        <span class="account-name" v-if="user.company && user.company.accountName">({{ user.company.accountName }})</span>
      </span>

      <v-chip
        label
        small
        :color="resolveUserRoleVariant(user.role)"
        :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(
          user.role,
        )}--text text-capitalize`"
      >
        {{ user.role }}
      </v-chip>
    </v-card-title>

    <v-card-text>
      <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

      <v-divider></v-divider>

      <v-list>
        <v-list-item dense class="px-0 mb-n2">
          <span class="font-weight-medium text-no-wrap me-2">Email:</span>
          <span class="email text--secondary">{{ user.email }}</span>
        </v-list-item>

        <v-list-item dense class="px-0 mb-n2">
          <span class="font-weight-medium text-no-wrap me-2">Phone:</span>
          <span class="phone text--secondary">
             <v-tooltip v-if="user.isPhoneVerified" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on">
                  {{ icons.mdiCheckCircleOutline }}
                </v-icon>
              </template>
              <span>Phone verified</span>
            </v-tooltip>
            {{ user.phone }}</span>
        </v-list-item>

        <v-list-item dense class="px-0 mb-n2">
          <span class="font-weight-medium me-2">Status:</span>
          <span class="status text--secondary">
            <v-chip
              small
              label
              :color="resolveUserStatusVariant(user.status)"
              :class="`v-chip-light-bg ${resolveUserStatusVariant(
                user.status,
              )}--text font-weight-medium text-capitalize`"
            >
              {{ user.status }}
            </v-chip>
          </span>
        </v-list-item>

        <v-list-item dense class="px-0 mb-n2">
          <span class="font-weight-medium me-2">Role:</span>
          <span class="role text--secondary text-capitalize">{{ user.role }}</span>
        </v-list-item>

        <v-list-item dense class="px-0 mb-n2" v-if="user.role === role.ADMIN" >
          <span class="font-weight-medium me-2">Sales Bonus:</span>
          <span class="sales-bonus text--secondary text-capitalize">{{ user.salesBonus || 0 }}%</span>
        </v-list-item>

      </v-list>

      <user-company v-if="user.company" :company="user.company"></user-company>
      <user-bank v-if="user.bank" :bank="user.bank"></user-bank>
    </v-card-text>

    <v-card-actions v-if="isAdmin" class="justify-center">
      <v-btn color="primary" class="edit-button me-3" @click="edit()"> Edit </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiCheck,
  mdiBriefcaseVariantOutline,
  mdiCheckboxBlankCircle,
  mdiCheckCircleOutline
} from '@mdi/js';
import { avatarText } from '@core/utils/filter';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';
import UserCompany from '@/views/shared/users/_partials/UserCompany.vue';
import UserBank from '@/views/shared/users/_partials/UserBank.vue';
import { eventBus } from '@/main';
import { resolveUserRoleVariant, resolveUserStatusVariant } from '@/views/shared/users/useUsers';

export default {
  methods: { resolveUserRoleVariant, resolveUserStatusVariant },
  components: {
    UserBank,
    UserCompany,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const isAdmin = getProfile().role === Roles.ADMIN;
    const role = Roles;

    const edit = () => {
      eventBus.$emit('user-edit.modal.show');
    };

    return {
      isAdmin,
      role,
      edit,
      avatarText,
      icons: {
        mdiCheckCircleOutline,
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    };
  },
};
</script>
<style>
.email {
  overflow: auto;
}
</style>
