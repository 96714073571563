<template>
  <v-dialog
    v-model="isWebsiteVisaOipDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-website-visa-oip-dialog-open', false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Verifi OIP </v-card-title>

      <v-card-text v-if="visaOipSettings && websiteData" class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="12">
              <v-switch v-model="visaOipSettings.isEnable" :label="`Enable verifi visa oip?`"></v-switch>
            </v-col>
            <v-row v-if="visaOipSettings.isEnable">
              <v-col cols="12" sm="6">
                <div class="d-inline-flex w-full">
                  <v-text-field
                    v-model="visaOipSettings.userName"
                    outlined
                    dense
                    :disabled="true"
                    id="basic-auth-user-name"
                    :rules="[validators.required]"
                    type="text"
                    label="Basic auth user name"
                    placeholder="Basic auth user name"
                    hide-details="auto"
                    class="mb-2"
                  ></v-text-field>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        id='copy-auth-user-name-button'
                        v-bind="attrs"
                        v-on="on"
                        @click="copyValue(visaOipSettings.userName)"
                        class="pl-3 pr-2"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <div class="d-inline-flex w-full">
                  <v-text-field
                    :type="showApiBasicAuthPassword ? 'text' : 'password'"
                    :disabled="true"
                    outlined
                    dense
                    v-model="visaOipSettings.password"
                    hide-details
                    id="basic-auth-password"
                    label="Basic auth password"
                    class="merchanto-oip-user-password mb-2"
                  ></v-text-field>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="showApiBasicAuthPassword = !showApiBasicAuthPassword" class="pl-3 pr-2">
                        {{ showApiBasicAuthPassword ? icons.mdiEye : icons.mdiEyeOff }}
                      </v-icon>
                    </template>
                    <span>Show Secret Key</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        id='copy-auth-password-button'
                        v-bind="attrs"
                        v-on="on"
                        @click="copyValue(visaOipSettings.password)"
                        class="pl-3 pr-2"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </div>
              </v-col>

              <v-col cols="12">
                <v-btn id="generate-username-and-password-button" v-if="!visaOipSettings.password" x-large block color="info" @click="generateBasicAuth()">Generate user name & password</v-btn>
                <v-btn id="regenerate-password-button" v-else x-large block color="info" @click="reGenerateBasicAuth()">Re-generate password</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="visaOipSettings.webhook"
                  outlined
                  dense
                  id="webhook"
                  :rules="[validators.required]"
                  type="text"
                  label="Webhook"
                  placeholder="Webhook"
                  hide-details="auto"
                  class="mb-2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="visaOipSettings.clientId"
                  outlined
                  dense
                  id="client-id"
                  :rules="[validators.required]"
                  type="text"
                  label="Client Id"
                  placeholder="Client Id"
                  hide-details="auto"
                  class="mb-2"
                ></v-text-field>
              </v-col>
              <v-col id='send-email-notification-checkbox' cols="12" sm="12">
                <v-checkbox
                  v-model="visaOipSettings.isSendEmail"
                  label="Send email notification"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-website-visa-oip-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { emailValidator, required, passwordValidatorIf } from '@core/utils/validation';
import Vue from 'vue';
import { copyValue } from '@core/utils';
import { mdiContentCopy, mdiEye, mdiEyeOff } from '@mdi/js';
import RolesEnum from '@/enums/roles.enum';

export default {
  props: {
    isWebsiteVisaOipDialogOpen: {
      type: Boolean,
      required: true,
    },
    websiteData: {
      required: false,
    },
  },
  setup(props, { emit }) {
    const visaOipSettings = ref();

    const loadVisaOipSettings = async websiteId => {
      return await store.dispatch('visaOip/fetchVisaOipSettingsByWebsiteId', websiteId);
    };
    const loadMerchantAdmin = async () => {
      const users = (await store
        .dispatch('user/fetchAll', {
          page: 1,
          role: RolesEnum.MERCHANT,
          companyId: props.websiteData.company.id,
          itemsPerPage: '-1',
        })).data;
      return users.length > 0 ? users[0] : '';
    };

    const showApiBasicAuthPassword = ref(false);
    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const onSubmit = () => {
      if (valid.value) {
        Vue.$confirm({
          message: 'Are you sure you want change API credentials?',
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: confirm => {
            if (confirm) {
              store
                .dispatch('visaOip/updateVisaOipSettingsByWebsiteId', {
                  websiteId: props.websiteData.id,
                  visaOipSettings: visaOipSettings.value,
                })
                .then(() => {
                  emit('refetch-data');
                  emit('update:is-website-visa-oip-dialog-open', false);
                })
                .catch(e => {
                  Vue.notify({
                    type: 'error',
                    title: 'Visa Oip settings',
                    text: e.response.data.message,
                    duration: 5000,
                  });
                });
            }
          },
        });
      } else {
        validate();
      }
    };

    const generateBasicAuth = () => {
      visaOipSettings.value.userName = `mrhnt_oip_${props.websiteData.company.id}`;
      visaOipSettings.value.password = Math.random().toString(36).slice(-10);
    };

    const reGenerateBasicAuth = () => {
      Vue.$confirm({
        message: 'Are you sure you want to regenerate API Basic Auth? All API implementations with old API Basic Auth will not work anymore!',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: confirm => {
          if (confirm) {
            generateBasicAuth();
          }
        },
      });
    };

    watch(
      () => props.isWebsiteVisaOipDialogOpen,
      async () => {
        const merchantAdmin = await loadMerchantAdmin();
        visaOipSettings.value ={
          userName: null,
          password: null,
          isEnable: false,
          webhook: null,
          isSendEmail: false,
          clientId: '',
          notificationEmail: merchantAdmin.email,
        };
        const visaOipSettingsResponse = await loadVisaOipSettings(props.websiteData.id);
        if (visaOipSettingsResponse) {
          visaOipSettings.value = {
            ...visaOipSettings.value,
            ...visaOipSettingsResponse,
          };
        }
      },
    );

    return {
      form,
      visaOipSettings,
      onSubmit,
      valid,
      validate,
      copyValue,
      generateBasicAuth,
      reGenerateBasicAuth,
      showApiBasicAuthPassword,
      validators: { required, passwordValidatorIf, emailValidator },
      icons: {
        mdiContentCopy,
        mdiEye,
        mdiEyeOff,
      }
    };
  },
};
</script>
