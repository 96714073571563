<template>
  <div class="website-tab-overview">
    <merchant-website-add-new
      v-model="isAddNewWebsiteSidebarActive"
      @refetch-data="fetchWebsites(companyId)"
    ></merchant-website-add-new>
    <merchant-website-visa-oip
      :is-website-visa-oip-dialog-open.sync="isWebsiteVisaOipDialogOpen"
      :website-data="selectedWebsite"
    >
    </merchant-website-visa-oip>
    <v-card class="mb-7">
      <v-card-title> Website List </v-card-title>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="website-search-query website-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-website mb-4 me-3"
            @click.stop="isAddNewWebsiteSidebarActive = !isAddNewWebsiteSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Website</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table :headers="tableColumns" :item-class="websiteItemClass" :items="websitesListTable" :loading="loading">
        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-edit-dialog :return-value.sync="item.status" large persistent @save="updateStatus(item.id, item.status)">
            <v-chip
              small
              :color="resolveWebsiteStatusVariant(item.status)"
              :class="`${resolveWebsiteStatusVariant(item.status)}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.status }}
            </v-chip>
            <template #input>
              <div class="mt-4 title">Update Status</div>
              <v-select
                class="website-select-new-status"
                v-model="item.status"
                :items="statusOptions"
                item-text="title"
                item-value="value"
                label="Status"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn id="website-actions-button" icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="website-list-item-view" :to="{ name: 'website-view', params: { websiteId: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Events Settings</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="website-list-item-view" :to="{ name: 'website-event-pricing', params: { websiteId: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiWallet }}
                  </v-icon>
                  <span>Events Pricing</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item :to="{ name: 'website-payment-settings', params: { websiteId: item.id } }" class="website-list-item-payment-settings"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiContactlessPaymentCircleOutline }}
                  </v-icon>
                  <span>Payment settings</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="website-list-item-verifi-oip"
                @click="
                  selectedWebsite = item
                  isWebsiteVisaOipDialogOpen = !isWebsiteVisaOipDialogOpen
                "
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiChartTimeline }}
                  </v-icon>
                  <span>Verifi OIP</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiChartTimeline,
  mdiContactlessPaymentCircleOutline,
  mdiPlus, mdiWallet
} from '@mdi/js';
import useWebsitesList from '@/views/shared/users/merchant/merchant-website/useWebsitesList';
import { ref } from '@vue/composition-api';
import MerchantWebsiteAddNew from '@/views/shared/users/merchant/merchant-website/MerchantWebsiteAddNew';
import store from '@/store';
import Vue from 'vue';
import MerchantWebsiteVisaOip from '@/views/shared/users/merchant/_partials/MerchantVisaOip.vue';

export default {
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  components: {
    MerchantWebsiteVisaOip,
    MerchantWebsiteAddNew,
  },
  setup(props) {
    const { websitesListTable, tableColumns, loading, searchQuery, resolveWebsiteStatusVariant, fetchWebsites } = useWebsitesList(props.companyId);

    const selectedWebsite = null;
    const isWebsiteVisaOipDialogOpen = ref(false);
    const isAddNewWebsiteSidebarActive = ref(false);

    const statusOptions = [
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
      { title: 'Deleted', value: 'deleted' },
    ];

    const updateStatus = (websiteId, status) => {
      store.dispatch('website/patchStatus', { id: websiteId, status: { status: status } }).catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Websites',
          text: 'Cannot update website status',
        });
      });
    };

    const websiteItemClass = () => {
      return 'website-item';
    };

    return {
      statusOptions,
      updateStatus,
      selectedWebsite,
      tableColumns,
      websitesListTable,
      isWebsiteVisaOipDialogOpen,
      resolveWebsiteStatusVariant,
      fetchWebsites,
      loading,
      searchQuery,
      isAddNewWebsiteSidebarActive,
      websiteItemClass,
      icons: {
        mdiDotsVertical,
        mdiWallet,
        mdiFileDocumentOutline,
        mdiContactlessPaymentCircleOutline,
        mdiDeleteOutline,
        mdiChartTimeline,
        mdiPlus,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
